import React from "react";

function UnderConstructions() {
  return (
    <div className="bg-blue-200 flex  place-items-center text-center h-screen justify-center ">
      <div>
        <img
          src="/Images/logo.png"
          alt="alpine_logo"
          className="mx-auto w-[50%] lg:w-[20%] "
        />
        <h1 className="text-3xl font-sans mt-5 font-bold text-gray-600  lg:text-7xl ">
          {" "}
          COMING SOON
        </h1>
        <h1 className="mt-5 text-[25px] text-gray-700">Under Construction</h1>
      </div>
    </div>
  );
}

export default UnderConstructions;
